<template>
  <div>
    <img
      src="../assets/legend-of-aurum-draconis-smaller.png"
      alt="The Legend Of Aurum Draconis"
      class="logo"
    />
    <div class="grid">
      <h4 class="label">Enter a hero id</h4>
      <div class="input-grid">
        <input
          v-model="id"
          type="number"
          placeholder="Enter hero id"
          max="2126"
          min="1"
          class="hero-id"
          @change="getHero"
        />
        <BlueButton size="extra-small" type="submit" @click="getHero"
          >Search</BlueButton
        >
      </div>
    </div>
  </div>
  <div v-if="error !== null">
    <h3>{{ error }}</h3>
  </div>
  <div v-if="isLoading">
    <h2>Loading...</h2>
  </div>
  <template v-else-if="heroData">
    <div class="hero-item">
      <hr />
      <h2>{{ heroData.metadata.name }}</h2>
      <div class="grid">
        <img
          class="hero-image pulse"
          :src="`https://ik.imagekit.io/dcg/${heroData.image}?tr=w-500`"
          alt="{{heroData.metadata.name}}"
        />
        <div class="traits">
          <div class="level">
            <span class="type">Level</span>
            <span class="value">{{ heroData.level }}</span>
          </div>
          <div :class="['rank']">
            <span class="type">Status</span>
            <span :class="['value', heroData.isDead ? 'red' : 'green']">{{
              heroData.isDead ? "Dead" : "Alive"
            }}</span>
          </div>
          <div
            v-for="trait in heroData.metadata.attributes"
            :key="trait.value"
            class="rank"
          >
            <span class="type">{{ trait.trait_type }}</span>
            <span class="value">{{ trait.value }}</span>
          </div>
        </div>
      </div>
      <hr />
      <div class="marketplace-links">
        <a :href="joepegs" rel="nofollow" target="_blank"> Buy On Joepegs </a>
      </div>
    </div>
  </template>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import BlueButton from "../components/Buttons/BlueButton.vue";
// import { useRoute } from "vue-router";
import Constants from "../consts/constants";
export default {
  components: {
    BlueButton,
  },
  setup() {
    const error = ref(null);
    const joepegs = ref(null);
    const heroData = ref(null);
    const hasId = ref(false);
    const id = ref("");
    const isLoading = ref(false);
    // const nftTrade = ref(null);
    // const kalao = ref(null);

    const axios = inject("axios"); // inject axios
    const route = useRoute();

    onMounted(async () => {
      if (route.query.id && route.query.id * 1 < 3000) {
        id.value = parseInt(route.query.id);
        await getHeroData();
      }
    });
    const getHeroData = async () => {
      try {
        if (parseInt(id.value) > 0 && parseInt(id.value) < 3000) {
          const response = await axios.get(
            "https://heroes.dragoncrypto.io/metadata/" + id.value
          );
          const response2 = await axios.get(
            Constants.apiUrl + `get-hero-data?id=${id.value}`
          );
          console.log(response2.data);
          if (response2.data.success) {
            heroData.value = response2.data;
            heroData.value["metadata"] = response.data;
            joepegs.value = `https://joepegs.com/item/avalanche/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14/${id.value}/`;
          } else {
            error.value = response.data.message;
            heroData.value = null;
          }
        } else {
          error.value = "Please enter a valid hero id";
          heroData.value = null;
        }
      } catch (error) {
        console.log(error);
        error.value = "Error Fetching Hero Data";
        heroData.value = null;
      }
    };

    const getHero = async () => {
      isLoading.value = true;
      error.value = null;
      if (id.value === "") {
        heroData.value = null;
      } else {
        await getHeroData();
      }
      isLoading.value = false;
    };

    return { heroData, joepegs, hasId, error, id, getHero, isLoading };
  },
};
</script>
<style lang="scss" scoped>
.input-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 304px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 0 auto;
}
.logo {
  width: 200px;
  margin: 2rem 0 0;
}
.hero-id {
  width: 100px;
  padding: 0.25rem 1rem;
  text-align: center;
}
.label {
  margin: 1rem 0;
}
.hero-item {
  padding: 1rem 0 3rem;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 576px) {
    padding: 2rem 0 3rem;
  }
  .grid {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
  .hero-image {
    width: 100%;
    /* max-width: 350px; */
    margin-bottom: 1rem;
    z-index: 5;
    @media only screen and (max-width: 576px) {
      width: 90%;
      margin: 0 auto 1rem;
    }
  }
  h2 {
    @media only screen and (max-width: 576px) {
      font-size: 1.4rem;
    }
  }
  .traits {
    display: grid;
    margin: 0 auto 1rem;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    @media only screen and (max-width: 576px) {
      width: 90%;
      margin: 0 auto 1rem;
    }
    .type {
      display: block;
      background-color: #e5c356;
      color: #000;
      padding: 0.2rem;
      font-size: 1rem;
      font-weight: bold;
    }
    .value {
      display: block;
      padding: 0.5rem;
      font-size: 1rem;
      font-weight: 100;
      font-family: "Cinzel", serif;
      text-transform: uppercase;
      border: 1px solid #fff;
      border-top: none;
      &.green {
        background-color: rgb(1 167 1 / 54%);
      }
      &.red {
        background-color: rgb(167 1 1 / 57%);
      }
    }
  }
  hr {
    border-color: #505558;
    margin: 1rem 0;
  }
  .marketplace-links {
    color: #7e7e7e;
    a {
      color: #7e7e7e;
      text-decoration: none;
    }
  }
}

.pulse {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 20px 5px rgba(204, 169, 44, 0.1);
  }
  50% {
    box-shadow: 0 0 50px 8px rgba(204, 169, 44, 0.3);
  }
  100% {
    box-shadow: 0 0 20px 5px rgba(204, 169, 44, 0.1);
  }
}
</style>
